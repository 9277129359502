<div class="content">
  <p-card header="{{ crag?.name }}">
    <div class="flex gap-1">
      <lc-closed-spot-tag *ngIf="crag?.closed"></lc-closed-spot-tag>
      <lc-secret-spot-tag *ngIf="crag?.secret"></lc-secret-spot-tag>
    </div>
    <p-breadcrumb
      class="max-w-full"
      [model]="breadcrumbs"
      [home]="breadcrumbHome"
      *ngIf="crag?.slug !== environment.skippedSlug"
    ></p-breadcrumb>

    <p-tabMenu [model]="items" [scrollable]="true" *ngIf="crag"></p-tabMenu>

    <router-outlet name="cragContent"></router-outlet>
  </p-card>
</div>
