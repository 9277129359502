<ng-container *isGymMode="true">
  <ng-container *transloco="let t; read: 'archiveButton'">
    <!-- eslint-disable @angular-eslint/template/eqeqeq -->
    <p-button
      [styleClass]="
        'p-button-secondary archive-button'
          .concat(!showLabel ? ' p-button-icon-only' : ' p-button-text')
          .concat(style == 'outline' ? ' p-button-outlined p-button-sm' : '')
          .concat(style == 'rounded' ? ' p-button-rounded' : '')
          .concat(style == 'plain' ? ' p-button-text p-button-rounded' : '')
      "
      data-cy="archive-button"
      label="{{ !getCurrentState() ? t('archive') : t('unarchive') }}"
      (click)="toggleArchived($event)"
      [ngClass]="{ 'hide-label': !showLabel }"
    >
      <ng-template pTemplate="icon">
        <i *ngIf="!getCurrentState()" class="pi pi-hammer"></i>
        <i *ngIf="getCurrentState()" class="pi pi-undo"></i>
      </ng-template>
    </p-button>
  </ng-container>
</ng-container>
