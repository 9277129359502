<div class="line-name">
  <h3>{{ line.name }}</h3>
  <div class="grade">
    {{ line | lineGrade }}
  </div>
</div>

<div class="close-icon" (click)="ref.close()" tabindex="0">
  <i class="pi pi-times"></i>
</div>
