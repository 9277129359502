<div class="content">
  <p-card header="{{ sector?.name }}">
    <div class="flex gap-1">
      <lc-closed-spot-tag *ngIf="sector?.closed"></lc-closed-spot-tag>
      <lc-secret-spot-tag *ngIf="sector?.secret"></lc-secret-spot-tag>
    </div>

    <p-breadcrumb
      class="max-w-full"
      [model]="breadcrumbs"
      [home]="breadcrumbHome"
      *ngIf="sector?.slug !== environment.skippedSlug"
    ></p-breadcrumb>

    <p-tabMenu [model]="items" [scrollable]="true" *ngIf="sector"></p-tabMenu>

    <router-outlet name="sectorContent"></router-outlet>
  </p-card>
</div>
