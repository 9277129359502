<ng-container *transloco="let t">
  <p-skeleton
    width="100%"
    height="2rem"
    *ngIf="gradeDistributionEmpty"
  ></p-skeleton>

  <ng-container *ngIf="!gradeDistributionEmpty">
    <div class="mb-3" *ngFor="let data of stackChartData">
      <p-meterGroup
        [value]="data.meterValues"
        [min]="0"
        [max]="data.total - data.projects"
      >
        <ng-template pTemplate="label">
          <ol
            class="p-metergroup-labels p-component p-metergroup-labels-horizontal"
          >
            <li
              class="p-metergroup-label font-bold"
              *ngIf="data.total - data.projects > 0"
            >
              {{ data.total - data.projects }}
              {{ stackChartData.length > 1 ? data.gradeScale : "" }}
              {{ t("lines") }}:
            </li>
            <li
              class="p-metergroup-label"
              *ngFor="let meterValue of data.meterValues"
            >
              <p-tag
                value="{{ meterValue.value }} {{ meterValue.label }}"
                [style]="{
                  backgroundColor: meterValue.color,
                  color: textColor(meterValue.color, '#2e3132'),
                }"
              />
            </li>
            <li class="p-metergroup-label" *ngIf="data.projects > 0">
              <p-tag
                value="{{ data.projects }} {{
                  t('leveledGradeDistributionProjects')
                }}"
                [style]="{
                  backgroundColor: '#e5e7eb',
                  color: textColor('#e5e7eb', '#2e3132'),
                }"
              />
            </li>
          </ol>
        </ng-template>
      </p-meterGroup>
    </div>
  </ng-container>
</ng-container>
